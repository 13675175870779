import React, { useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/commons/Layout';
import styled from 'styled-components';
import theme from '../constants/theme';
import CasiCard from '../components/cases/CasiCard';
import ResponsiveText from '../components/typography/ResponsiveText';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'gatsby-plugin-react-i18next';

const CasiStudioWrapper = styled.div`
  margin-bottom: 50px;

  .page-wrapper {
    min-height: 100vh;
  }

  .content-wrapper {
    position: relative;
  }

  .filters {
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 5;

    .filter-trigger {
      display: none;
      font-family: ${theme.fontSyne};
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: -2px;
      line-height: 34px;
      padding: 20px 20px;
      color: ${theme.colorWhite};
      background-color: ${theme.colorBlack};
      margin: 0 -20px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
    }

    .filter-item {
      font-family: ${theme.fontSyne};
      font-size: 20px;
      text-transform: uppercase;
      line-height: 34px;
      padding: 4px;
      color: ${theme.colorBlack};
      cursor: pointer;
      z-index: 2;
      border: 1px solid ${theme.colorBlack};
      text-align: center;

      &.active,
      &:hover {
        background-color: ${theme.colorBlack};
        color: ${theme.colorWhite};
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .casi-grid {
    display: grid;
    margin-top: 0;
    row-gap: 30px;
    column-gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    .casi-block {
      margin: 0;
      width: 100%;

      .caso-card {
        & > div {
          width: 100%;
          // min-width: 420px;
          height: 460px;
          /* height: auto;
          max-height: unset; */
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .filters {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;

      .filter-trigger {
        display: flex;
      }

      ul {
        display: none;

        &.active {
          display: block;
        }

        li {
          color: ${theme.colorBlack};
          margin: 0 -20px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: ${theme.colorWhite};

          &.active {
            background-color: ${theme.colorYellow};
          }
        }
      }
    }

    .casi-grid {
      display: grid;
      margin-top: 0;
      row-gap: 30px;
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr);

      .casi-block {
        margin: 0;
        width: 100%;

        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2n + 1) {
          margin-top: 0;
        }

        .caso-card {
          & > div {
            width: 100%;
            height: 460px;
            /* height: auto;
            max-height: unset; */
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .casi-grid {
      grid-template-columns: unset;
    }
  }

  // SMALL SCREENS
  @media (max-width: 374px) {
    .filters {
      .filter-trigger,
      ul > li {
        font-size: 22px;
      }
    }

    .casi-block[data-title='LloydsFarmacia'] {
      h3 {
        font-size: 20px;
      }
    }
  }
`;

const CasiStudioPage = ({ data }) => {
  const {
    backend: { casis, seoRules },
  } = data;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');

  const filters = {
    all: casis.length,
  };
  casis.forEach((caso) => {
    const tags = caso.tags.split(',');
    tags.forEach((tag) => {
      filters[tag.toLowerCase()] = 1 + (filters[tag.toLowerCase()] || 0);
    });
  });

  const filteredCases = useCallback(() => {
    if (activeFilter !== 'all') {
      return casis.filter((c) => {
        return c.tags.toLowerCase().includes(activeFilter.toLowerCase());
      });
    }

    return casis;
  }, [activeFilter, casis]);

  const handleChangeFilter = (name) => {
    setActiveFilter(name);
    setIsFilterOpen(false);
  };

  const sliderSettings = {
    height: 37,
    slidesPerView: 3.5,
    spaceBetween: 24,
    slideToClickedSlide: true,
    breakpoints: {
      768: {
        slidesPerView: 3.5,
      },
      0: {
        slidesPerView: 1.5,
      },
    },
  };

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <CasiStudioWrapper className="container mx-auto" data-scroll-section>
        <div className="page-wrapper">
          <ResponsiveText type="extreme">
            <h2>Casi Studio</h2>
          </ResponsiveText>
          <div className="content-wrapper">
            <div className="filters">
              {/* <div
                className="filter-trigger"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                {!isFilterOpen
                  ? activeFilter +
                    " (" +
                    filters[activeFilter.toLowerCase()] +
                    ")"
                  : "Filtra per:"}
                <span>
                  {isFilterOpen ? (
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </span>
              </div> */}
              {/* <ul className={isFilterOpen ? "active" : ""}> */}
              <Swiper {...sliderSettings}>
                {Object.keys(filters).map((filterName, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className={
                        filterName.toLowerCase() === activeFilter.toLowerCase()
                          ? 'filter-item active'
                          : 'filter-item'
                      }
                      onClick={() => handleChangeFilter(filterName)}
                    >
                      {filterName}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* </ul> */}
            </div>
            <div className="casi-grid">
              {filteredCases().map((c, i) => (
                <div key={i} className="casi-block" data-title={c.titolo}>
                  <Link to={'/casi-studio/' + c.slug} alt="">
                    <div className="caso-card">
                      <CasiCard card={c} linkChild={false} />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CasiStudioWrapper>
    </Layout>
  );
};

export default CasiStudioPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      casis {
        slug
        titolo
        tags
        immagineCard {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 560, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
      seoRules(where: { slug_eq: "/casi-studio/" }) {
        slug
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`;
